<template>
  <div :key="$route.params.brand_id">
    <Navigation />
    <transition name="fade" mode="out-in">
      <router-view v-if="selected_brand || $route.params.brand_id === 'new'" />
    </transition>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Navigation from "../components/Navigation";

export default {
  name: "BrandView",
  components: {
    Navigation,
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_brand_by_id", "set_selected_brand"]),
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
  },
  async created() {
    const brand_id = this.$route.params.brand_id;
    if (brand_id === "new") return;
    await this.get_brand_by_id(brand_id);
  },
  beforeRouteLeave(to, from, next) {
    this.set_selected_brand(null);
    next();
  },
  watch: {
    "$route.params.brand_id": {
      handler: async function (brand_id) {
        await this.get_brand_by_id(brand_id);
      },
    },
  },
};
</script>
