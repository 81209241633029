<template>
  <component
    :is="$vuetify.breakpoint.mdAndUp ? 'div' : 'v-menu'"
    class="d-flex mb-4"
    v-if="this.$route.params.brand_id !== 'new'"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="flex justify-space-between">
        <v-btn
          color="primary"
          rounded
          v-bind="attrs"
          v-on="on"
          elevation="0"
          class="mb-4"
          ><v-icon>mdi-chevron-down</v-icon>Menu</v-btn
        >
        <v-btn
          v-if="selected_brand"
          class="mr-0 d-flex d-md-none"
          color="black"
          outlined
          rounded
          elevation="0"
          :href="selected_brand.url"
          ><v-icon class="mr-2">mdi-eye</v-icon>Preview website</v-btn
        >
      </div>
    </template>
    <div class="d-flex flex-column flex-sm-row scroll-x pb-2">
      <ul
        class="
          flex-column flex-md-row
          align-md-center
          py-4
          px-6
          white
          br-8
          mr-md-3
        "
        v-for="(config, idx) in [main_pages, secondary_pages, other_pages]"
        :key="`navi_${idx}`"
      >
        <li
          v-for="(nav_el, i) in config"
          :key="`nav_${i}`"
          class="text-md-center"
        >
          <router-link
            class="text-capitalize position-relative"
            :class="{
              'router-link-exact-active': nav_el.active
                ? nav_el.active()
                : false,
            }"
            :to="{
              name: nav_el.name,
              params: { brand_id: $route.params.brand_id },
            }"
          >
            <v-icon v-if="nav_el.icon" class="mr-2">{{ nav_el.icon }}</v-icon
            >{{ nav_el.text }}
            <div v-if="nav_el.badge" class="badge">
              {{ nav_el.badge }}
            </div></router-link
          >
        </li>
      </ul>
    </div>
  </component>
</template>

<script>
import { mapState } from "vuex";
import { VMenu } from "vuetify/lib";
import { get_add_a_business_pending_requests_count } from "@/requests";

export default {
  name: "BrandIdNavigation",
  data() {
    return {
      pending_aab_count: 0,
    };
  },
  components: {
    VMenu,
  },
  async mounted() {
    try {
      const {
        data: { pending_count: count },
      } = await get_add_a_business_pending_requests_count(
        this.$route.params.brand_id
      );
      this.pending_aab_count = count;
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    main_pages() {
      return [
        { text: "Home", name: "admin-brands-id-home-page" },
        {
          text: "News",
          name: "admin-brands-id-news",
          active: () => this.$route.name.includes("news"),
        },
        { text: "Other Pages", name: "admin-brands-id-pages" },
      ];
    },
    secondary_pages() {
      return [
        {
          text: `Manage ${this?.selected_brand?.zone_label ?? "Zones"}s`,
          name: "admin-brands-id-zones",
        },
        {
          text: "Showcase My Shops",
          name: "admin-brands-id-promotions",
          active: () => this.$route.name.includes("promotion"),
        },
      ];
    },
    other_pages() {
      return [
        {
          text: "Settings",
          name: "admin-brands-id-edit",
          icon: "mdi-cog-outline",
        },
        {
          text: "Users",
          name: "admin-brands-id-users",
          icon: "mdi-account-group-outline",
        },
        {
          text: "Sign Up Requests",
          name: "admin-brands-id-requests",
          icon: "mdi-clipboard-file",
          badge: this.pending_aab_count,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
li,
a {
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  &.router-link-exact-active {
    .v-icon {
      color: var(--v-primary-base);
    }
  }
  &:not(.router-link-exact-active) {
    color: #666666;
  }
  &:hover {
    color: var(--v-primary-darken1);
    .v-icon {
      color: var(--v-primary-darken1);
    }
  }
}
ul {
  white-space: nowrap;
  display: flex;
  justify-content: left;
  list-style-type: none;
  gap: 1.5rem;
}

.badge {
  position: absolute;
  right: -20px;
  top: -5px;
  // width: 18px;
  // height: 18px;
  text-align: center;
  padding: 2px 4px 0;
  border-radius: 99px;
  color: white;
  background-color: var(--v-primary-base);
  font-size: 9px;
  font-weight: bold;
}
</style>
