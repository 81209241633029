var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$route.params.brand_id !== 'new')?_c(_vm.$vuetify.breakpoint.mdAndUp ? 'div' : 'v-menu',{tag:"component",staticClass:"d-flex mb-4",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"flex justify-space-between"},[_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"color":"primary","rounded":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")]),_vm._v("Menu")],1),(_vm.selected_brand)?_c('v-btn',{staticClass:"mr-0 d-flex d-md-none",attrs:{"color":"black","outlined":"","rounded":"","elevation":"0","href":_vm.selected_brand.url}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-eye")]),_vm._v("Preview website")],1):_vm._e()],1)]}}],null,false,117102957)},[_c('div',{staticClass:"d-flex flex-column flex-sm-row scroll-x pb-2"},_vm._l(([_vm.main_pages, _vm.secondary_pages, _vm.other_pages]),function(config,idx){return _c('ul',{key:("navi_" + idx),staticClass:"\n        flex-column flex-md-row\n        align-md-center\n        py-4\n        px-6\n        white\n        br-8\n        mr-md-3\n      "},_vm._l((config),function(nav_el,i){return _c('li',{key:("nav_" + i),staticClass:"text-md-center"},[_c('router-link',{staticClass:"text-capitalize position-relative",class:{
            'router-link-exact-active': nav_el.active
              ? nav_el.active()
              : false,
          },attrs:{"to":{
            name: nav_el.name,
            params: { brand_id: _vm.$route.params.brand_id },
          }}},[(nav_el.icon)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(nav_el.icon))]):_vm._e(),_vm._v(_vm._s(nav_el.text)+" "),(nav_el.badge)?_c('div',{staticClass:"badge"},[_vm._v(" "+_vm._s(nav_el.badge)+" ")]):_vm._e()],1)],1)}),0)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }